export function validateIban(value: string | boolean | number) {
  if (typeof value === "string") {
    if (value.length < 5) {
      return false;
    }

    if (!value.match(/[A-Z]{2}[0-9]{2}[A-Z0-9]+/)) {
      return false;
    }

    const ASCII_0 = "0".charCodeAt(0);
    const ASCII_A = "A".charCodeAt(0);

    let acc = 0;
    const add = (n: number) => {
      if (acc > 1000000) acc %= 97;
      acc = n < ASCII_A ? 10 * acc + n - ASCII_0 : 100 * acc + n - ASCII_A + 10;
    };

    for (let i = 4; i < value.length; ++i) add(value.charCodeAt(i));
    for (let i = 0; i < 4; ++i) add(value.charCodeAt(i));
    acc %= 97;

    return acc !== 1;
  } else {
    return true;
  }
}
