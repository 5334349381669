import { IConfig } from "process-ui-builder/config/types";

const gdprConfig: IConfig = {
  id: "gdpr_case",
  version: "0.0.1",
  checkedSteps: {
    path: "flow.checked_steps",
  },
  currentStep: {
    path: "flow.current_step",
  },
  steps: [
    {
      id: "data_validation",
      label: "Data Validation",
      readOnly: true,
      groups: [
        {
          id: "customer_data",
          type: "form",
          title: "Customer Data",
          size: {
            colSpan: 4,
          },
          readOnly: {
            path: "flow.read_only_group.customer_data",
          },
          fields: [
            {
              id: "salutation",
              label: "Salutation",
              type: "select",
              emptyValue: true,
              options: [
                {
                  label: "Frau",
                  value: "FEMININE",
                },
                {
                  label: "Herr",
                  value: "MASCULINE",
                },
              ],
              value: { path: "case.customer.salutation" },
            },
            {
              id: "first_name",
              label: "First Name",
              type: "text",
              validation: {
                required: true,
                maxLength: 100,
                minLength: 2,
              },
              value: { path: "case.customer.first_name" },
            },
            {
              id: "last_name",
              label: "Last Name",
              type: "text",
              validation: {
                required: true,
                maxLength: 100,
                minLength: 2,
              },
              value: { path: "case.customer.last_name" },
            },
            {
              id: "email",
              label: "Email",
              type: "email",
              validation: {
                required: true,
              },
              value: { path: "case.customer.email" },
            },
            {
              id: "newsletter_optin",
              label: "Newsletter OptIn?",
              type: "checkbox",
              value: { path: "case.customer.newsletter_optin" },
            },
          ],
        },
        {
          id: "claim_data",
          type: "form",
          size: {
            colSpan: 4,
          },
          title: "Claim Data",
          readOnly: true,
          fields: [
            {
              id: "email",
              label: "Email",
              type: "email",
              validation: {
                required: true,
              },
              value: { path: "case.claim.email" },
            },
            {
              id: "company_id",
              label: "Company",
              type: "select",
              emptyValue: false,
              options: {
                handler: "getCompanies",
                type: "handler",
              },
              validation: {
                required: true,
              },
              value: { path: "case.claim.company_id" },
            },
            {
              id: "email_compromised",
              label: "Email Address is compromised.",
              type: "checkbox",
              value: { path: "case.claim.email_compromised" },
            },
          ],
        },
        {
          id: "offer_details",
          type: "form",
          title: "Offer Details",
          size: {
            colSpan: 4,
          },
          readOnly: true,
          fields: [
            {
              id: "promo_code",
              label: "Promo Code",
              type: "text",
              validation: {
                maxLength: 50,
                minLength: 4,
              },
              readOnly: true,
              value: { path: "case.promo_code" },
            },
            {
              id: "claim_amount",
              label: "Claim Amount",
              type: "money",
              validation: {
                required: true,
              },
              value: { path: "case.active_offer.claim_amount" },
              readOnly: true,
            },
            {
              id: "offer_amount",
              label: "Offer Amount",
              type: "money",
              value: { path: "case.active_offer.offer_amount" },
              validation: {
                required: true,
                regex: "^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$",
              },
            },
          ],
        },
        {
          id: "docs",
          type: "customComponent",
          component: "CaseDocumentList",
        },
      ],
    },
    {
      id: "offer",
      label: "Offer",
      groups: [
        {
          id: "active_offer",
          type: "customComponent",
          component: "ActiveOfferCard",
        },
        {
          id: "offer_history",
          type: "customComponent",
          component: "OfferHistoryCard",
        },
        {
          id: "address",
          type: "form",
          size: {
            colSpan: 4,
          },
          title: "Address",
          readOnly: {
            path: "flow.read_only_group.address_data",
          },
          fields: [
            {
              id: "street",
              label: "Street",
              type: "text",
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.street" },
            },
            {
              id: "postal_code",
              label: "Postal Code",
              type: "text",
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.postal_code" },
            },
            {
              id: "city",
              label: "City",
              type: "text",
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.city" },
            },
            {
              id: "country_code",
              label: "Country",
              type: "select",
              emptyValue: true,
              options: [
                {
                  label: "Germany",
                  value: "DE",
                },
              ],
              validation: {
                required: true,
              },
              value: { path: "case.offer.address.country_code" },
            },
          ],
          onSubmit: {
            type: "handler",
            handler: "changeOfferAddress",
            description: "Change Offer Address",
          },
        },
        {
          id: "bank_account",
          type: "form",
          title: "Bank Account",
          size: {
            colSpan: 4,
          },
          readOnly: true,
          fields: [
            {
              id: "iban",
              label: "IBAN",
              type: "text",
              validation: {
                required: true,
                iban: true,
              },
              value: { path: "case.offer.bank_account.iban" },
            },
          ],
        },
      ],
    },
    {
      id: "assignment",
      label: "Assignment",
      groups: [
        {
          id: "active_assignment",
          type: "customComponent",
          component: "ActiveAssignmentDetails",
        },
        {
          id: "assignment_history",
          type: "customComponent",
          component: "AssignmentHistory",
        },
        {
          id: "assignment_previews",
          type: "customComponent",
          component: "AssignmentPreviews",
        },
      ],
      stepActions: {
        component: "StepThreeActions",
      },
    },
    {
      id: "payment",
      label: "Payment",
      groups: [
        {
          id: "active_payment",
          type: "customComponent",
          component: "ActivePaymentDetails",
        },
        {
          id: "payment_history",
          type: "customComponent",
          component: "PaymentHistory",
        },
      ],
    },
  ],
};

export default gdprConfig;
