import { Validation } from "process-ui-builder/config";
import { validateIban } from "./iban";
import { validateMax } from "./max";
import { validateMaxLength } from "./maxLength";
import { validateMin } from "./min";
import { validateMinLength } from "./minLength";
import { validateRegex } from "./regex";
import { validateRequired } from "./required";

//TODO validator refactoring and detailed testing
export const validator = (input: string | boolean | number, validator: Validation | undefined) => {
  if (!validator) {
    return false;
  }

  let requiredError: boolean = false,
    maxLengthError: boolean = false,
    minLengthError: boolean = false,
    minError: boolean = false,
    maxError: boolean = false,
    regexError: boolean = false,
    ibanError: boolean = false;

  if (validator.required) {
    requiredError = !validateRequired(input);
  }

  if (validator.maxLength) {
    maxLengthError = !validateMaxLength(input, validator.maxLength);
  }

  if (validator.minLength) {
    minLengthError = !validateMinLength(input, validator.minLength);
  }

  if (validator.min) {
    minError = !validateMin(input, validator.min);
  }

  if (validator.max) {
    maxError = !validateMax(input, validator.max);
  }

  if (validator.regex) {
    regexError = !validateRegex(input, validator.regex);
  }

  if (validator.iban) {
    ibanError = !validateIban(input);
  }

  return (
    requiredError ||
    maxLengthError ||
    minLengthError ||
    minError ||
    maxError ||
    regexError ||
    ibanError
  );
};
